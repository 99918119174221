export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113')
];

export const server_loads = [0];

export const dictionary = {
		"/": [6],
		"/actions/karma": [8],
		"/auth": [9],
		"/bloomerinvite": [10],
		"/bloomers-without-borders": [11],
		"/chat": [12],
		"/chat/all": [13],
		"/chat/creatives": [14],
		"/chat/water": [15],
		"/chat/water/aridLandscapes": [16],
		"/communityposts": [17],
		"/create/[type]": [18],
		"/donate": [19],
		"/edit/[slug]": [20],
		"/engage/communityCalls": [21],
		"/engage/contributors": [22],
		"/engage/donate": [23],
		"/engage/localBlooms": [24],
		"/engage/privacy": [25],
		"/engage/projects": [26],
		"/home": [27],
		"/joinABloom": [49],
		"/joinold": [50],
		"/join": [28],
		"/join/bloomers-without-borders": [29],
		"/join/localbloom": [30],
		"/join/localmember": [31],
		"/join/localmember/login": [32],
		"/join/newjoin": [33],
		"/join/register": [34],
		"/join/register/bloomerswithoutborders": [35],
		"/join/register/bloomerswithoutborders/apply": [36],
		"/join/register/helper": [37],
		"/join/register/joinlocal": [38],
		"/join/register/joinlocal/apply": [39],
		"/join/register/location": [40],
		"/join/register/onlinecontributor": [41],
		"/join/register/onlinecontributor/apply": [42],
		"/join/register/orientation": [43],
		"/join/register/schedule": [44],
		"/join/register/selectpath": [45],
		"/join/register/startlocalhub": [46],
		"/join/register/you": [47],
		"/join/registrationMagic": [48],
		"/learn/faq": [51,[2]],
		"/learn/faq/actionPosts": [52,[2]],
		"/learn/faq/businessAgreements": [53,[2]],
		"/learn/faq/localmembers": [54,[2]],
		"/learn/faq/membership": [55,[2]],
		"/learn/syllabus": [56,[3]],
		"/learn/syllabus/art": [57,[3]],
		"/learn/syllabus/economy": [58,[3]],
		"/learn/syllabus/intro": [59,[3]],
		"/learn/syllabus/nature": [60,[3]],
		"/learn/syllabus/tech": [61,[3]],
		"/learn/templates": [62,[4]],
		"/learn/templates/beautifyCommons": [63,[4]],
		"/learn/templates/communitygarden": [64,[4]],
		"/learn/templates/conflictResolution": [65,[4]],
		"/learn/templates/createActionTemplate": [66,[4]],
		"/learn/templates/cropswap": [67,[4]],
		"/learn/templates/csaa": [68,[4]],
		"/learn/templates/emailList": [69,[4]],
		"/learn/templates/eventFollowup": [70,[4]],
		"/learn/templates/eventReminder": [71,[4]],
		"/learn/templates/farmlandcommons": [72,[4]],
		"/learn/templates/firstEmail": [73,[4]],
		"/learn/templates/heartOfBloom": [74,[4]],
		"/learn/templates/kickoff": [75,[4]],
		"/learn/templates/legacy": [76,[4]],
		"/learn/templates/longevity": [77,[4]],
		"/learn/templates/mediaTeam": [78,[4]],
		"/learn/templates/meditations": [79,[4]],
		"/learn/templates/mission": [80,[4]],
		"/learn/templates/proposalTemplate": [81,[4]],
		"/learn/templates/roles": [82,[4]],
		"/learn/templates/thinktank": [83,[4]],
		"/learn/templates/urbanfarm": [84,[4]],
		"/learn/templates/vetting": [85,[4]],
		"/localpage/new": [87],
		"/localpage/[location]": [86],
		"/marketplace": [88],
		"/member": [89],
		"/member/begin": [92],
		"/member/blogs": [93],
		"/member/bounty": [94],
		"/member/bounty/scratch": [95],
		"/member/dms": [96],
		"/member/dms/[slug]": [97],
		"/member/localactionrewards": [98],
		"/member/localactionrewards/impactreport": [99],
		"/member/localactionrewards/localmembers": [100],
		"/member/nobloomyet": [101],
		"/member/notifications": [102],
		"/member/viewGifts": [103],
		"/member/viewSkills": [104],
		"/member/[username]": [90],
		"/member/[username]/profile": [91],
		"/profile": [105],
		"/startLocal": [106],
		"/team": [107],
		"/verify": [108],
		"/verify/dashboard": [110],
		"/verify/[slug]": [109],
		"/view": [111],
		"/view/[slug]": [112],
		"/vision": [113],
		"/[location]": [7]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';